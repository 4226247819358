.termination_start_page {
    section.confirmation_employee {
        .img_rounded {
            height: 3em;
            width: 3em;
        }

        .item_user{
            .title{
                font-weight: bold;
            }
        }
    }

    section.termination_data {
        .form_section{
            font-size: 1.1em;
            font-weight: bold;
            margin-top: 1em;
            margin-bottom: 0.5em;
            color: var(--color-blue);
        }
    }

    section.attachments {
        .file_item {
            border: solid thin var(--color-blue);
            border-radius: 5px;
            padding: 0.5em;
            display: flex;
            align-items: center;

            .file_text{
                flex-grow: 1;
                margin: 0 0.5em;
                overflow-x: hidden;
                text-overflow: ellipsis;
            }
        }
    }
}