.new_features_component{
    .banner_new_features{
        max-width: 90vw;
        max-height: 70vh;
        object-fit: contain;
    }

    .carousel.carousel-slider .control-arrow {
        background-color: black;
    }
}